<template>
  <div class="home">
    <h1>Hello Maldeva</h1>
    <h3>Comming Soon !</h3>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

export default defineComponent({
  name: "HomeView",
  components: {
    // HelloWorld,
  },
});
</script>
